import React from 'react';
import { Nav } from '@fluentui/react/lib/Nav';
import './style.css';
import { Helmet } from 'react-helmet';

const navLinks = [
  {
    links: [
      {
        name: 'Home',
        url: '#',
        expandAriaLabel: 'Expand Home section',
        collapseAriaLabel: 'Collapse Home section',
      },
      {
        name: 'Interview',
        url: '#',
        expandAriaLabel: 'Expand Interview section',
        collapseAriaLabel: 'Collapse Interview section',
      },
      {
        name: 'Career Advice',
        url: '#',
        expandAriaLabel: 'Expand Career Advice section',
        collapseAriaLabel: 'Collapse Career Advice section',
      },     
      {
        name: 'Contact Us',
        url: '#',
        expandAriaLabel: 'Expand Contact Us section',
        collapseAriaLabel: 'Collapse Contact Us section',
      },
    ],
  },
];
export const App = () => {
	return (
		<div>
		  <Helmet>
			<title>Boost Your Tech Career</title>
			<meta name="description" content="Take your tech career to the next level with our expert guidance and support." />
			<meta name="keywords" content="boost, tech, career, interview, coaching, advice" />
		  </Helmet>
		  <div className="ms-Grid" dir="ltr">
			<div className="ms-Grid-row">
			  <div className="ms-Grid-col ms-sm2 ms-xl2">
				<Nav groups={navLinks} />
			  </div>
			  <div className="ms-Grid-col ms-sm10 ms-xl10">
				<h1>BOOST YOUR TECH CAREER</h1>
				<p>Take your tech career to the next level with our expert guidance and support.</p>
				<div className="ms-Grid-row">
				  <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6 ms-xl6">
					<h2>Interview Coaching</h2>
					<p>Get the skills and confidence you need to ace your next interview.</p>
				  </div>
				</div>
				<div className="ms-Grid-row">
				  <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6 ms-xl6">
					<h2>Career Advice</h2>
					<p>Our experts will help you navigate the complex world of tech careers.</p>
				  </div>
				</div>
				<div className="ms-Grid-row">
				  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12">
					<h2>Contact Us</h2>
					<p>Get in touch with us today to learn more about how we can help you boost your tech career.</p>
				  </div>
				</div>
			  </div>
			</div>
		  </div>
		</div>
	  );
};

export default App;
